import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Button, Section, Box, Text } from "../../components/Core";

import { device } from "../../utils";




const StyledSection = styled(Box)`

.font-light{
  font-weight: 300;
  color: black;
}

.padding-fix{
  padding-bottom: 10px;
}


`





const TextBlock = ({title, title_color, copy, pb=0, bg="white", mt=0, pt=5}) => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <StyledSection pt={pt} pb={pb} mt={mt} css={{backgroundColor: bg}}>
        <Container>
          <Title mb={2} color={title_color} className='padding-fix'>
            {title}
          </Title>
          <Text dangerouslySetInnerHTML={{ __html: copy }} className='font-light'>
          </Text>
        </Container>
      </StyledSection>
    </>
  );
};

export default TextBlock;
