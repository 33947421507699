import React from "react";
import { Element } from "react-scroll";

import PageWrapper from "../components/PageWrapper";
import HeroStatic from "../sections/peel-creative/HeroStaticThankyou";
import TextBlock from "../sections/peel-creative/TextBlock";
import styled from "styled-components";
import { Title, Button, Section, Box, Text } from "../components/Core";

import HeroImage from "../assets/peel-creative/contact_hero.jpg"
import { Container, Row, Col } from "react-bootstrap";

const StyledRow = styled(Row)`
input{
  width: 100%;
  border-radius: 10px;
  border: 1px solid #9fc0c5;
  height: 40px;
}

textarea{
  width: 100%;
  border-radius: 10px;
  border: 1px solid #9fc0c5;
  height: 120px;
}


.label{
  padding-left: 10px;
  font-weight: bold;
  color: black;
  font-size: 18px;
}

form{
  width: 100%;
  max-width: 480px;
}

button{
  min-width: 60%;
  background-color: #9fc0c5;
  height: 40px;
  border-radius: 10px;
  text-align: right;
  margin-left: auto;
  border: none;
}

button p{
  color: white;
  font-size: 18px;
  font-weight: bold;

  padding-right: 25%;
}

`

const IndexPage = () => {
  return (
    <>
      <PageWrapper>
        <HeroStatic image={HeroImage} />
        
      </PageWrapper>
    </>
  );
};
export default IndexPage;
